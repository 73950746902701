import React from "react";
import {
  Box,
  Typography,
  Avatar,
  Button,
  Grid,
  IconButton,
  Link,
} from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import LanguageIcon from "@mui/icons-material/Language";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PinterestIcon from "@mui/icons-material/Pinterest";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const iconMap = {
  facebook: FacebookIcon,
  twitter: TwitterIcon,
  instagram: InstagramIcon,
  linkedin: LinkedInIcon,
  youtube: YouTubeIcon,
  pinterest: PinterestIcon,
};

const Elegant = ({ businessCard, companyConfig, handleAddToContacts }) => {
  const firstName = businessCard.name.split(" ")[0];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minHeight: "100vh",
        backgroundColor: "#DCDCDC", // Light gray background
        fontFamily: "Century Gothic, sans-serif", // Elegant font matching design
      }}
    >
      <Box
        sx={{
          position: "relative", // Enable positioning for children
          width: "100%",
          height: "300px",
          backgroundImage: `url(${companyConfig.backgroundImage || ""})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        {/* Background Overlay */}
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.4)", // Semi-transparent overlay for contrast
            zIndex: 1, // Ensure overlay is above background
          }}
        ></Box>

        {/* Company Logo */}
        <Box
          sx={{
            position: "absolute",
            top: "15%", // Adjusted to ensure it doesn't overlap with the avatar
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 2, // Ensure logo is above the overlay
            padding: "8px", // Optional: Add padding for the logo container
          }}
        >
          <img
            src={companyConfig.logo}
            alt={`${companyConfig.name} Logo`}
            style={{
              maxWidth: "150px", // Adjust the size of the logo
              height: "auto",
            }}
          />
        </Box>
      </Box>

      {/* Profile Avatar */}
      <Avatar
        alt={businessCard.name}
        src={businessCard.profilePicURL}
        sx={{
          width: 300, // Larger width
          height: 300, // Larger height
          marginTop: "-150px", // Overlapping effect
          border: "5px solid white",
          zIndex: 3, // Ensure the avatar is on top of everything
          position: "relative", // Ensure it respects its stacking order
        }}
      />

      {/* Name */}
      <Typography
        variant="h4"
        sx={{
          fontFamily: "'Great Vibes', cursive", // Elegant font
          fontWeight: "600",
          marginTop: "16px",
          color: "#333",
          textAlign: "center",
          fontSize: "2.5rem",
        }}
      >
        {businessCard.name}
      </Typography>

      {/* Position */}
      <Typography
        variant="subtitle1"
        sx={{
          textTransform: "uppercase",
          fontSize: "1rem",
          letterSpacing: "2px",
        }}
      >
        {businessCard.position || "Professional"}
      </Typography>

      <Typography
        variant="body1"
        sx={{
          fontSize: "1rem",
          color: "#333",
          lineHeight: "1.6", // Improve readability
          mt: "10px",
          padding: "20px",
          textAlign: "justifyCenter",
        }}
        component="div" // Render as a div to contain multiple paragraphs
      >
        {businessCard.bio
          ? businessCard.bio.split("\n").map((paragraph, index) => (
              <Box
                component="p"
                key={index}
                sx={{ margin: 0, marginBottom: "10px" }}
              >
                {paragraph.trim()}
              </Box>
            ))
          : `This is a brief introduction about ${businessCard.name}.`}
      </Typography>

      {/* </Box> */}

      {/* Contact Details as Equal Grid */}
      <Grid
        container
        spacing={1}
        sx={{
          width: "90%", // Extended width close to screen edges
          maxWidth: "1200px", // Prevent overly wide layout on larger screens
          margin: "0 auto",
          mb: 2,
        }}
      >
        <Grid
          item
          xs={6}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <EmailIcon sx={{ marginRight: "8px", color: "#333" }} />
          <Button
            sx={{
              textTransform: "none",
              color: "#333",
              fontWeight: "bold",
              justifyContent: "start",
            }}
            href={`mailto:${businessCard.email}`}
          >
            {/* Email {businessCard.name} */}
            Email {firstName}
          </Button>
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <PhoneIcon sx={{ marginRight: "8px", color: "#333" }} />
          <Button
            sx={{
              textTransform: "none",
              color: "#333",
              fontWeight: "bold",
              justifyContent: "start",
            }}
            href={`tel:${businessCard.phone}`}
          >
            Call {firstName}
          </Button>
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <LanguageIcon sx={{ marginRight: "8px", color: "#333" }} />
          <Button
            sx={{
              textTransform: "none",
              color: "#333",
              fontWeight: "bold",
              justifyContent: "start",
            }}
            href={companyConfig.website || "#"}
            target="_blank"
          >
            Visit Website
          </Button>
        </Grid>
        {/* <Grid
          item
          xs={6}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <LocationOnIcon sx={{ marginRight: "8px", color: "#333" }} />
          <Button
            sx={{
              textTransform: "none",
              color: "#333",
              fontWeight: "bold",
              justifyContent: "start",
            }}
          >
            {businessCard.address || "Address"}
          </Button>
        </Grid> */}
        <Grid
          item
          xs={6}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <LanguageIcon sx={{ marginRight: "8px", color: "#333" }} />
          <Button
            sx={{
              textTransform: "none",
              color: "#333",
              fontWeight: "bold",
              justifyContent: "start",
            }}
            href={"https://sasainteriors-bw.com/contact/"}
            target="_blank"
          >
            Location & Address
          </Button>
        </Grid>
      </Grid>

      {/* Social Media */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: "16px",
          marginBottom: "24px",
          width: "90%", // Extended width
          maxWidth: "1200px", // Prevent overly wide layout on large screens
        }}
      >
        {companyConfig.socialIcons &&
          Object.entries(companyConfig.socialIcons).map(([platform, url]) => {
            const IconComponent = iconMap[platform];
            if (!IconComponent) return null; // Skip unsupported platforms
            return (
              <IconButton
                key={platform}
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                sx={{ color: "#333" }}
              >
                <IconComponent />
              </IconButton>
            );
          })}
      </Box>

      {/* Add to Contacts Button */}
      <Button
        variant="contained"
        sx={{
          width: "90%", // Extend button width closer to screen edges
          maxWidth: "1200px", // Prevent overly wide button on large screens
          backgroundColor: "#333",
          color: "#FFF",
          fontWeight: "bold",
          padding: "12px",
          borderRadius: "30px",
          mb: 4,
        }}
        onClick={handleAddToContacts}
      >
        Add to Contacts
      </Button>
    </Box>
  );
};

export default Elegant;
